import classnames from "classnames";
import { TModel } from "../../../types";

import * as findModelStyles from "../find-model.module.scss";

import { Item } from "./Item";
import * as styles from "./model.module.scss";
import { useEffect, useState } from "react";

type ModelProps = {
    models: TModel[];
    selectedCode?: string | null;
    onClick?: (id: string) => void;
};

export const Model = ({ models, selectedCode, onClick }: ModelProps) => {
    const translationState = (window as any).app.preloadState.translation;

    const [filteredModels, setFilteredModels] = useState(models);
    const [filter, setFilter] = useState("");

    useEffect(() => {
        const escapedFilter = filter.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
        const regexFilter = new RegExp(escapedFilter, 'i');
        setFilteredModels(models.filter(model => regexFilter.test(model.name)));
    }, [filter, models]);

    return (
        <>
            <p className="description firstLetterCapital" id="machine-selection-models">{translationState["machineSelection.selectModel"]}</p>
            <div className={styles.searchInput}>
                <input placeholder={translationState["machineSelection.searchModel"]} type="text" className={styles.searchInput} value={filter} onInput={ev => setFilter(ev.currentTarget.value ?? "")}></input>
            </div>
            <div
            className={classnames(findModelStyles.list, styles.list)}
        >
            {filteredModels.map((model) => (
                <Item
                    key={model.code}
                    code={model.code}
                    name={model.name}
                    onClick={onClick}
                    selected={model.code === selectedCode}
                />
            ))}
        </div>
    </>);
} 
